/* eslint-disable react-hooks/exhaustive-deps */
import 'components/UploadDialog/UploadDialog.scss';

import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {useSnackbar} from 'notistack';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';
import { useFormatMessage } from 'utils/translate';
import {API_STATUSES, API_LEAFLET_MANAGEMENT_LEAFLETS} from 'config/api/constants';
import {IRootState} from 'shared/reducers';
import {leafletUploadDialogToggle} from 'shared/reducers/leafletUpload';
import ButtonClose from 'components/Buttons/ButtonClose';
import FileUpload from 'components/FileUpload/FileUpload';
import LeafletUploadCard from './LeafletUploadCard';
import {PaperX} from 'components/PaperX';
import {CountrySelector} from 'components/Selectors';
import {setPreferredCountry} from 'shared/reducers/userProfileActions';
import {mediumTypeList, mediumType} from 'shared/models/leaflet.model';
import { DialogAuxiliaryActions } from 'components/DialogAuxiliaryActions';
import { NavigationMenuButton } from 'modules/NavigationMenu';

export enum leafletUploadDialogActions {
    CANCEL ='CANCEL',
    REMOVE = 'REMOVE',
    RETRY = 'RETRY'
}

const LeafletUploadDialog = () => {
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const translate = useFormatMessage();

    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);

    const [country, setCountry] = useState(countryMarket.preferredCountry);
    const dialogOpen = useSelector((state: IRootState) => state.leafletUpload.dialogOpen);
    const [selectedMediumType, setSelectedMediumType] = useState<mediumType>(mediumType.LEAFLET);
    const [preventSimilarityOperations, setPreventSimilarityOperations] = useState<boolean>(false);
    const [files, setFiles] = useState([]);
    const [q, setQ] = useState([]);

    const [currentFileStatus, setCurrentFileStatus] = useState(null);
    const [currentFile, setCurrentFile] = useState(null);
    const [currentCountry, setCurrentCountry] = useState(null);
    const [currentMediumType, setCurrentMediumType] = useState<mediumType>(null);
    const [currentPreventSimilarityOperations, setCurrentPreventSimilarityOperations] = useState<boolean>(null);

    const cancelFileUpload = useRef(null);

    const handleFilesChange = (files: File[]) => setFiles(files);

    useEffect(() => {
        setCountry(countryMarket.preferredCountry);
    }, [countryMarket])

    useEffect(() => {
        // append new 'payload' objects to queue, extended with uuid, country, medium type and prevent flag
        setQ(q.map((item) => item).concat(files.map((file) => ({status: API_STATUSES.NEW, id: uuidv4(), file, country: country, mediumType: selectedMediumType, preventSimilarityOperations}))));
    }, [files]);

    useEffect(() => {
        if (!q.find((item) => item.status === API_STATUSES.PENDING)) {
            // nothing pending, check if new files available to upload
            const nextItem = q.find((item) => item.status === API_STATUSES.NEW);
            if (nextItem) {
                setCurrentFile(nextItem);
                setCurrentFileStatus(API_STATUSES.NEW);
                setCurrentCountry(nextItem.country);
                setCurrentMediumType(nextItem.mediumType);
                setCurrentPreventSimilarityOperations(nextItem.preventSimilarityOperations);
            }
        }
    }, [q]);

    useEffect(() => {
        switch (currentFileStatus) {
            case API_STATUSES.NEW:
                uploadFile();
                return;
            case API_STATUSES.IDLE:
                setQ(q.map((item) => item.id === currentFile.id ? {file:{name: currentFile.file.name, size: currentFile.file.size}, id: currentFile.id, status: API_STATUSES.IDLE, country: currentCountry, mediumType: currentMediumType} : item));
                notifyIfAllDone();
                return;
            case API_STATUSES.ERROR:
                setQ(q.map((item) => item.id === currentFile.id ? {...currentFile, status: API_STATUSES.ERROR} : item));
                return;
            case API_STATUSES.CANCELLED:
                setQ(q.map((item) => item.id === currentFile.id ? {...currentFile, status: API_STATUSES.CANCELLED} : item));
                return;
            default: return null;
        }
    }, [currentFileStatus]);

    const uploadFile = () => {
        setCurrentFileStatus(API_STATUSES.PENDING);
        // @TODO use success/error snacks....
        const newQ = q.map((item) => item.id === currentFile.id ? {...item, status: API_STATUSES.PENDING} : item);
        setQ(newQ);

        const formData = new FormData();
        const {file} = currentFile;
        formData.append('leafletFile', file, file.name);

        axios.post(API_LEAFLET_MANAGEMENT_LEAFLETS, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            cancelToken: new axios.CancelToken(
                cancel => (cancelFileUpload.current = cancel)
            ),
            params: {
                country: currentCountry,
                mediumType: currentMediumType,
                preventSimilarityOperations: currentPreventSimilarityOperations
            }
        }).then(() => {
            setCurrentFileStatus(API_STATUSES.IDLE);
        }).catch((error) => {
            if (error.__CANCEL__) {
                setCurrentFileStatus(API_STATUSES.CANCELLED);
            } else {
                enqueueSnackbar(`${translate({id: 'leafletUpload.uploadFailed'})}`, {variant: 'error'});
                setCurrentFileStatus(API_STATUSES.ERROR);
            }
        });

    };

    const handleClose = () => dispatch(leafletUploadDialogToggle(false));

    const handleCardClick = (id, action) => {
        const callingItem = q.find((item) => item.id === id);
        if (callingItem) {
            switch (action) {
                case leafletUploadDialogActions.CANCEL:
                    if (cancelFileUpload.current) {
                        cancelFileUpload.current('cancelled');
                    }
                    return;
                case leafletUploadDialogActions.RETRY:
                    setQ(q.map((item) => item.id === id ? {...item, status: API_STATUSES.NEW} : item));
                    return;
                default: // aka leafletUploadDialogActions.REMOVE
                    setQ(q.filter((item) => item.id !== id));
                    return;
            }
        }
    };

    const clearUploaded = () => setQ(q.filter((item) => item.status !== API_STATUSES.IDLE));

    const clearAll = () => {
        if (cancelFileUpload.current) {
            cancelFileUpload.current('cancelled');
        }
        setQ([]);
    };

    const notifyIfAllDone = () => {
        if (q.length && !q.find((item) => item.status === API_STATUSES.NEW)) {
            enqueueSnackbar(`${translate({id: 'leafletUpload.uploadSuccess'})}`, {variant: 'success'});
        }
    };

    const handleCountryChange = (value) => {
        setCountry(value);
        dispatch(setPreferredCountry(value));
    }

    return  (<Dialog open={dialogOpen} fullScreen className="fileUploadDialogRoot">
        <DialogContent>
            <div className="_directionCol">
                <PaperX>
                    <div className="_formRowDouble noMargins">
                        <div className="_formRowDouble noMargins">
                            <CountrySelector onCountryChange={handleCountryChange} value={country}/>
                            <FormControl fullWidth>
                                <InputLabel color="primary">
                                    <FormattedMessage id="b.mediumType"/>
                                </InputLabel>
                                <Select color="primary"
                                        label={translate({id: 'b.mediumType'})}
                                        size="medium"
                                        defaultValue={selectedMediumType}
                                        value={selectedMediumType}
                                        onChange={(e) => setSelectedMediumType(e.target.value as mediumType)}
                                >
                                    {mediumTypeList.map(item => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {translate({id: item.label})}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="_formRowDouble noMargins">
                            <FormControlLabel
                                control={<Checkbox checked={preventSimilarityOperations} color="primary" onChange={(e) => setPreventSimilarityOperations(e.target.checked)}/>}
                                label={translate({id: 'fileUpload.preventIdenticalRecognition'})}/>
                        </div>
                    </div>
                </PaperX>
                <div className="_directionRow">
                    <div className="uploadContainer">
                        <FileUpload
                            selectedMediumType={selectedMediumType}
                            onFilesChange={handleFilesChange}
                        />
                    </div>
                    <div className="_directionCol uploadListContainer">
                        <div className="_scrollY">
                            <ul>
                                {q.map((item) => (<li key={item.id} ><LeafletUploadCard id={item.id} fileName={item.file.name} fileSize={item.file.size} status={item.status} country={item.country} onClick={handleCardClick}/></li>))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            <DialogAuxiliaryActions>
                <NavigationMenuButton/>
            </DialogAuxiliaryActions>
            <Button onClick={clearUploaded} variant="outlined" color="secondary"> {translate({id: 'leafletUpload.clearUploaded'})}</Button>
            <Button onClick={clearAll} variant="outlined" color="secondary"> {translate({id: 'leafletUpload.clearAll'})}</Button>
            <ButtonClose onClick={() => handleClose()} />
        </DialogActions>
    </Dialog>)
};

export default LeafletUploadDialog;