import {spotActions} from 'modules/Medium/Spot/SpotOverview/SpotOverview';
import {promotionSave} from 'shared/models/promotion.model';
import {apiGetPromotionsForFrameResponsePromotionType} from 'shared/models/frame.model';
import {mediumType} from 'shared/models/leaflet.model';

export enum spotStatus {
    REGISTERED = 'REGISTERED', // verification needed
    PENDING = 'PENDING', // waiting for AI proposals
    VERIFIED = 'VERIFIED', // after verification or no need it - ready to describe
    DONE = 'DONE' // finished, ready
}

export enum promotionMediumType {
    PROMOTION = 'PROMOTION',
    PRESS_ADVERTISEMENT = 'PRESS_ADVERTISEMENT',
    RADIO_SPOT = 'RADIO_SPOT',
    TV_SPOT = 'TV_SPOT'
}

export type mediumPromotionsPayload = {
    mediumId: string,
    promotionMediumType: promotionMediumType,
    promotions: promotionSave[]
}

export type spotHeaderData = {
    bannerId: string,
    bannerName: string,
    countryThemedWeeks: string[],
    language: string,
    themeSeasons: string[],
    transcription: string,
    validFrom: string,
    validTo: string
}

export type spotResponseModel = {
    country: string,
    headerData: spotHeaderData,
    spotId: string,
    status: spotStatus,
    transcriptProposals: string[],
    metaData: {
        sourceFile: string
        storageFileName: string
    },
    mediumType: mediumType
}

export type spotVerificationNextResponseModel = {
    spotId?: string
} | null

export type spotPutPostPayloadModel = {
    country: string,
    headerData: {
        mediumType: string;
        bannerId?: string,
        countryThemedWeeks?: string[],
        language: string,
        themeSeasons?: string[],
        transcription?: string,
        validFrom?: string,
        validTo?: string
    }
}

export type spotOverviewRow = {
    availableActions: spotActions[],
    banner: string,
    createdAt: string,
    createdBy: string,
    id: string,
    language: string,
    modifiedBy: string,
    quickAction: spotActions,
    status: string,
    validFrom: string,
    validTo: string,
    mediumType: mediumType
}

export type spotFilters = {
    bannerId?: string,
    countryId?: string,
    language?: string,
    status?: spotStatus[],
    validFrom?: string,
    validTo?: string,
    mediumType?: mediumType[],
}

export type mediaSummary = {
    banner: string,
    language: string,
    validFrom: string,
    validTo: string
}

export const spotTemplate: spotResponseModel = {
    country: '',
    headerData: {
        bannerId: '',
        bannerName: '',
        countryThemedWeeks: [],
        language: '',
        themeSeasons: [],
        transcription: '',
        validFrom: '',
        validTo: ''
    },
    spotId: '',
    status: null,
    transcriptProposals: [],
    metaData: {
        sourceFile: '',
        storageFileName: ''
    },
    mediumType: mediumType.RADIO_SPOT
};

export type promotionsForSpotResponseModel = {
    mediumId: string,
    promotions: apiGetPromotionsForFrameResponsePromotionType[],
    validFrom: string,
    validTo: string
};