import {Client} from '@stomp/stompjs';
import {WS_PAGE_LOCK} from 'config/webSocket/brokerURL';
import {pageLockCurrentFrame} from 'config/webSocket/destinations';

export const connectWebSocketPageLock = async (
    accessToken: () => Promise<string>,
    sendCurrentData: (client: Client) => void,
    onMessage: (msg) => void
): Promise<Client | null> => {
    try {
        const token: string = await accessToken();

        const stompClient = new Client({
            brokerURL: WS_PAGE_LOCK,
            connectHeaders: {'Authorization': `Bearer ${token}`,},
            onConnect: () => {
                stompClient.subscribe(pageLockCurrentFrame, (message) => {
                    const parsedMsg = JSON.parse(message.body);
                    onMessage(parsedMsg);
                });
                sendCurrentData(stompClient);
            },
            onStompError: (frame) => console.error(`STOMP error: ${frame.headers['message']}, details: ${frame.body}`),
        });
        stompClient.activate();
        return stompClient;
      } catch (error) {
          console.error(`Error fetching token: ${error}`);
          return null;
      }
};