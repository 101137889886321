import {multiselectAttributeDataModel} from './attributeComponent.model';

export type headerData = {
    bannerId: string,
    bannerName: string,
    branches: string[],
    countryThemedWeeks: string[],
    isGross: boolean | null,
    language: string,
    leafletRepresentativeBranchId: string,
    leafletRepresentativeBranchName: string,
    leafletTypes: string[],
    pageValidities: pageValidityBE[],
    pageCountryThemedWeeks: pageCountryThemedWeeksBE[],
    pageThemeSeasons: pageThemeSeasonBE[],
    pagePromotionTypes: pagePromotionTypesBE[],
    promotionTypes: string[],
    themeSeasons: string[],
    validFrom: string,
    validTo: string
}

export interface leafletModel {
    country: string,
    leafletId: string,
    headerData: headerData,
    metaData: leafletMetaData
}

export interface leafletPayloadModel {
    bannerId: string,
    branches: string[],
    countryThemedWeeks: string[],
    isGross: boolean,
    language: string,
    leafletRepresentativeBranchId: string,
    leafletTypes: string[],
    pageCountryThemedWeeks: pageCountryThemedWeeksBE[],
    pageThemeSeasons: pageThemeSeasonBE[],
    pagePromotionTypes: pagePromotionTypesBE[],
    pageValidities: pageValidityBE[],
    promotionTypes: string[],
    themeSeasons: string[],
    validFrom: string,
    validTo: string,
}

type validity = {
    validFrom: string,
    validTo: string
}

export type pageValidity = {
    pageNumber: string,
    validFrom: Date,
    validTo: Date,
    modified: boolean
}

export type pageValidityBE = {
    pageNumber: number,
    validFrom: string,
    validTo: string
}

export type pageValidityBEGrouped = {
    pageNumber: number[],
    validFrom: string,
    validTo: string
}

export type pageValidityBEGroupedWithRanges = {
    pageNumber: number[][],
    validFrom: string,
    validTo: string
}

export type pageValidityBEPageRangesStringified = {
    pageNumber: string,
    validFrom: string,
    validTo: string
}

export type validateResult = {
    hasError: boolean,
    duplicatePages?: number[]
}

export type pageThemeSeason = {
    pageNumber: string,
    themeSeasons: multiselectAttributeDataModel,
    modified: boolean
}

export type pageThemeSeasonBE = {
    pageNumber: number,
    themeSeasonIds: string[]
}

export type pageThemeSeasonBEGrouped = {
    pageNumber: number[],
    themeSeasonIds: string[]
}

export type pageThemeSeasonBEGroupedWithRanges = {
    pageNumber: number[][],
    themeSeasonIds: string[]
}

export type pageThemeSeasonBEPageRangesStringified = {
    pageNumber: string,
    themeSeasonIds: string[]
}

export type pagePromotionTypes = {
    pageNumber: string,
    promotionTypes: multiselectAttributeDataModel,
    modified: boolean
}

export type pagePromotionTypesBE = {
    pageNumber: number,
    promotionTypeIds: string[]
}

export type pagePromotionTypesBEGrouped = {
    pageNumber: number[],
    promotionTypeIds: string[]
}

export type pagePromotionTypesBEGroupedWithRanges = {
    pageNumber: number[][],
    promotionTypeIds: string[]
}

export type pagePromotionTypesBEPageRangesStringified = {
    pageNumber: string,
    promotionTypeIds: string[]
}

export type pageCountryThemedWeeks = {
    pageNumber: string,
    themedWeeks: multiselectAttributeDataModel,
    modified: boolean
}

export type pageCountryThemedWeeksBE = {
    pageNumber: number,
    countryThemedWeekIds: string[]
}

export type pageCountryThemedWeeksBEGrouped = {
    pageNumber: number[],
    countryThemedWeekIds: string[]
}

export type pageCountryThemedWeeksBEGroupedWithRanges = {
    pageNumber: number[][],
    countryThemedWeekIds: string[]
}

export type pageCountryThemedWeeksBEPageRangesStringified = {
    pageNumber: string,
    countryThemedWeekIds: string[]
}


export type leafletMetaData = {
    numberOfPages: number,
    fileName: string
}

export type leafletProgress = {
    currentUsersCount: number,
    currentUsers: string[],
    describedFrames: number,
    reportedFrames: number,
    reportedPages: number,
    totalFrames: number,
}

export type leafletSummary = {
    banner: string,
    repBranch: string,
    pageNumber: number,
    numberOfPages: number,
    leafletValidity: validity,
    pageValidity: validity
}

export type leafletRepresentativeBranch = {
    id: string,
    repBranch: string
}

export type printMediaOverviewTableRow = {
    availableActions: string[],    // @TODO obtain enums from BE, possible values "ENTER_GLOBAL_HEADER_DATA", "DESCRIBE_FRAMES", "EDIT_GLOBAL_HEADER_DATA", "FRAME", any more?; also review code and use enums where needed
    banner: string,
    createdAt: string,
    createdBy: string,
    fileName: string,
    id: string,
    language: string,
    leafletTypes: string[],
    mediumType: string,
    modifiedBy: string,
    numberOfPages: number,
    quickAction: string, // @TODO obtain enums from BE
    repBranch: string
    status: string,  // @TODO obtain enums from BE
    validFrom: string,
    validTo: string,
}

export interface leafletModelWithPageData {
    country: string,
    leafletId: string,
    headerData: headerData,
    metaData: leafletMetaData,
    pageData: {
        pageNumber: number,
        themeSeasons: string[],
        countryThemedWeeks: string[],
        promotionTypes: string[],
        validFrom: string,
        validTo: string
    }
}

export enum leafletStatus {
    ALL = 'ALL', // not real state, available option for filtering
    UPLOADED = 'UPLOADED', // desc ui: Paging in progress
    PAGED = 'PAGED', // desc ui: Require header data
    ENTERED_HEADER_DATA = 'ENTERED_HEADER_DATA', // Ready to frame
    FRAMED = 'FRAMED', // Ready to describe
    DESCRIBING_IN_PROGRESS = 'DESCRIBING_IN_PROGRESS', // temporary state when not all frames are yet described
    FRAMES_DESCRIBED = 'FRAMES_DESCRIBED',// temporary state between 'we know all frames are described' but not yet 'published(DONE)'
    // DONE = 'DONE' --> temporarly excluded because status Done & frames described are BE wise the same
}

export enum mediumType {
    LEAFLET = 'LEAFLET',
    PRESS_ADVERTISEMENT = 'PRESS_ADVERTISEMENT',
    RADIO_SPOT = 'RADIO_SPOT',
    TV_SPOT = 'TV_SPOT'
}

type mediumTypeKeys = keyof typeof mediumType;

export const mediumTypeTranslationsPrintMedia = {
    [mediumType.LEAFLET]: 'nav.leaflet',
    [mediumType.PRESS_ADVERTISEMENT]: 'leafletUpload.pressAdvertisement'
}
export const mediumTypeTranslationsSpots = {
    [mediumType.RADIO_SPOT]: 'spotUpload.radioSpot',
    [mediumType.TV_SPOT]: 'spotUpload.tvSpot',
}

type mediumTypeTranslation = {
    [key in mediumTypeKeys]: string;
};

export const allMediumTypeTranslations: mediumTypeTranslation = {
    ...mediumTypeTranslationsPrintMedia,
    ...mediumTypeTranslationsSpots
}

export const mediumTypeList = Object.keys(mediumTypeTranslationsPrintMedia).map(key => ({
    value: key,
    label: mediumTypeTranslationsPrintMedia[key],
  }));

export const mediumTypeListSpotUpload = Object.keys(mediumTypeTranslationsSpots).map(key => ({
    value: key,
    label: mediumTypeTranslationsSpots[key],
  }));

// templates
export const leafletTemplate: leafletModelWithPageData = {
    country: '',
    leafletId: '',
    headerData: {
        bannerId: '',
        bannerName: '',
        branches: [],
        countryThemedWeeks: [],
        isGross: true,
        language: '',
        leafletRepresentativeBranchId: '',
        leafletRepresentativeBranchName: '',
        leafletTypes: [],
        pageCountryThemedWeeks: [],
        pagePromotionTypes: [],
        pageValidities: [],
        pageThemeSeasons: [],
        promotionTypes: [],
        themeSeasons: [],
        validFrom: '',
        validTo: ''
    },
    pageData: {
        pageNumber: 0,
        validTo: '',
        validFrom: '',
        themeSeasons: [],
        countryThemedWeeks: [],
        promotionTypes: []
        
    },
    metaData: {
        numberOfPages: 0,
        fileName: ''
    }
};