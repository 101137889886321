/* eslint-disable react-hooks/exhaustive-deps */
import './MediaPreview.scss';

import {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import {useSnackbar} from 'notistack';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {API_STATUSES, API_MEDIA_SPOT} from 'config/api/constants';
import {spotResponseModel} from 'shared/models/media.model';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {useFormatMessage} from 'utils/translate';
import {responseValidation} from 'utils/responseValidation';

interface mediaPreviewProps {
    mediumId: string
}

const MediaPreview = ({mediumId}: mediaPreviewProps) => {
    const translate = useFormatMessage();
    const {enqueueSnackbar} = useSnackbar();

    const spotDataApiGet = useApi('get', {errMsg: 'media.spotFetchErr'});

    const [transcription, setTranscription] = useState<string>(null);

    const getSpotData = (mediumId: string) => {
        if (mediumId) spotDataApiGet.call(`${API_MEDIA_SPOT}/${mediumId}?acquireLock=false`);
    }

    useEffect(() => {
        getSpotData(mediumId);
    }, [mediumId]);

    useEffect(() => {
        if (spotDataApiGet.status === API_STATUSES.IDLE) {
            if (spotDataApiGet.data) {
                const response: spotResponseModel = spotDataApiGet.data;
                if (responseValidation(response) && response.headerData?.transcription) {
                    setTranscription(response.headerData.transcription);
                } else {
                    enqueueSnackbar(translate({id: 'media.errNoMedia'}), {variant: 'warning'});
                }
            } else {
                enqueueSnackbar(translate({id: 'media.errNoMedia'}), {variant: 'warning'});
            }
        }
        if (spotDataApiGet.status === API_STATUSES.ERROR) {
            enqueueSnackbar(translate({id: 'media.errNoMedia'}), {variant: 'warning'});
        }
    }, [spotDataApiGet.status]);

    return (
        <div className="mediaPreviewRoot">
            <LoadingOverlay show={spotDataApiGet.status === API_STATUSES.PENDING}/>
            {spotDataApiGet.status === API_STATUSES.IDLE &&
            <div className="transcriptionReadContainer">
                    <Typography variant="overline">{translate({id: 'media.transcription'})}</Typography>
                    <div className='transcriptValue'>
                        <Typography variant="body1" paragraph>
                            {transcription}
                        </Typography>
                    </div>
            </div>}
        </div>
    );
};

export default MediaPreview;