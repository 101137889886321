/* eslint-disable react-hooks/exhaustive-deps */
import './AdvertisementBrowserEditFramePromotions.scss';

import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useSnackbar} from 'notistack';
import {Client} from '@stomp/stompjs';
import {useAuth0} from '@auth0/auth0-react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { API_FRAME_PROPOSAL_ACTIVITY_TRACKER } from 'config/api/constants';
import {sendCurrentFrameDest} from 'config/webSocket/destinations';
import {currentFrameLockPayload} from 'shared/models/websocket.model';
import {promotionSuggestionsForFrame, promotionUI, PROPOSALS_ACTIVITY_TYPE} from 'shared/models/promotion.model';
import {productDetailsResponseModel, productSearchResultUI} from 'shared/models/product.model';
import {headerData, leafletModel} from 'shared/models/leaflet.model';
import {BPCOResponseModel} from 'shared/models/BPCO.model';
import {handyAttributes} from 'shared/handyAttributes';
import {IRootState} from 'shared/reducers';
import {getCurrencyForCountry} from 'shared/reducers/userProfile';
import {getObjectFromLocalStorage, storeInLocalStorage} from 'utils/storageUtils';
import {useActivityMonitor, timeIntervalInMin} from 'utils/useActivityMonitor';
import { useApi } from 'utils/axiosHooks/axiosHooks';
import {renewPageLock} from 'utils/lockUtils';
import {useFormatMessage} from 'utils/translate';
import {connectWebSocketPageLock} from 'utils/websockets';
import { useSlimAttributeQuery } from 'components/Attribute/attributeAPI';
import {PaperX} from 'components/PaperX';
import Promotion from 'components/Promotion';
import {LoadingOverlay} from 'components/LoadingOverlay';
import PromotionsTable from 'components/PromotionsTable';
import { bulkPromoUpdatePayload } from 'components/PromotionsTable/PromotionsTableEditDrawer';
import ToggleTemplates from 'components/Controls/ToggleTemplates';
import InfoDialog from 'components/InfoDialog/InfoDialog';
import ProductSearch from 'modules/Advertisement/FrameDescription/ProductSearch/ProductSearch';
import ProductDrawer, {productDrawerProps} from 'modules/Advertisement/FrameDescription/ProductDrawer/ProductDrawer';
import {
    extendOrOverwritePromotionType, newBPCODrawerProps,
    newProductDrawerProps
} from 'modules/Advertisement/FrameDescription/FrameDescriptionMVP';
import {
    addDerivedLeafletValues,
    BPCODetails2productSearchResultUI,
    productDetails2productSearchResultUI,
    transformPromotionsSuggestions
} from 'modules/Advertisement/FrameDescription/frameDescriptionUtils';
import {makePromotionOfProductSearch, transformPromotionsResponse} from 'modules/Advertisement/AdvertisementsOverview/advertisementsOverviewIO';
import {useGetPromotionsForFrame} from 'modules/Advertisement/AdvertisementsOverview/advertisementsOverviewAPI';
import BPCODrawer, {BPCODrawerProps} from 'modules/Advertisement/FrameDescription/BPCODrawer/BPCODrawer';
import PromotionDrawer from 'modules/Advertisement/FrameDescription/PromotionDrawer/PromotionDrawer';
import PromotionsSuggestions from 'modules/Advertisement/FrameDescription/PromotionsSuggestions/PromotionsSuggestions';

export enum modes {
    DETAILS = 'DETAILS',
    TABLE = 'TABLE'
}

interface advertisementBrowserDetailsProps {
    leafletData: leafletModel,
    newProductDrawerOpen?: boolean,
    newBPCODrawerOpen?: boolean,
    on404or409: Function,
    onDialogConfirm: () => void, 
    onProductDrawerClose?: Function,
    onBPCODrawerClose?: Function,
    isPromotionsSuggestionsOpen: boolean,
    onPromotionsModified: (promotions:promotionUI[]) => void,
    onPromotionsNotModified?: (promotions:promotionUI[]) => void,
    selectedFrameId: string,
    tabId: string,
    currentPage?: number,
    mode: modes,
}

const localStorageShowTemplates = 'showTemplates_edit_frame';

const AdvertisementBrowserEditFramePromotions = (props: advertisementBrowserDetailsProps) => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();
    const {activityCount} = useActivityMonitor(timeIntervalInMin);
    const {getAccessTokenSilently} = useAuth0();
    const stompClientRef = useRef<Client | null>(null);

    const {
        leafletData,
        on404or409,
        onDialogConfirm,
        onPromotionsModified,
        selectedFrameId,
        onPromotionsNotModified,
        newBPCODrawerOpen,
        newProductDrawerOpen,
        onBPCODrawerClose,
        onProductDrawerClose,
        isPromotionsSuggestionsOpen,
        currentPage,
        tabId,
        mode,
    } = props;

    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);

    const [promotions, setPromotions] = useState<promotionUI[]>([]);
    const promotionTypesDictionary = useSlimAttributeQuery(handyAttributes.promotionType, selectedLanguage, true);
    const themeSeasonDictionary = useSlimAttributeQuery(handyAttributes.themeSeason, selectedLanguage, true);
    const countryThemedWeeksDictionary = useSlimAttributeQuery(handyAttributes.countryThemedWeeks, selectedLanguage, true);
    const qualitySealsDictionary = useSlimAttributeQuery(handyAttributes.qualitySeal, selectedLanguage, true);
    const [isFirstLoad, setFirstLoad] = useState<boolean>(false);
    const [isInfoDialogOpen, setIsInfoDialogOpen] = useState<boolean>(false);

    const [productDrawerProperties, setProductDrawerProperties] = useState<productDrawerProps>(newProductDrawerProps);
    const [BPCODrawerProperties, setBPCODrawerProperties] = useState<BPCODrawerProps>(newBPCODrawerProps);
    const [promotionDrawerOpen, setPromotionDrawerOpen] = useState<boolean>(false);
    const [promotionInDrawerId, setPromotionInDrawerId] = useState<string>('');

    const [showTemplates, setShowTemplates] = useState<boolean>(() => {
        const storedShowTemplates = getObjectFromLocalStorage(localStorageShowTemplates);
        return storedShowTemplates !== null ? storedShowTemplates : true;
    });

    const proposalsActivityTrackerPost = useApi('post', {}, {});

    const {
        isLoading,
        data,
        isSuccess,
        refetch
    } = useGetPromotionsForFrame(selectedFrameId, selectedLanguage, true, tabId, on404or409);

    const handleWebSocketMsgReceive = (msg) => {
        if (msg?.expiredAt) setIsInfoDialogOpen(true);
    };

    const sendCurrentData = (stompClient: Client) => {
        if (stompClient && stompClient.connected) {
            const payload: currentFrameLockPayload = {frameId: selectedFrameId, tabId: tabId};
            stompClient.publish({
                destination: sendCurrentFrameDest,
                body: JSON.stringify(payload),
            });
        }
    };

    useEffect(() => {
        if (activityCount > 0 && !isInfoDialogOpen) renewPageLock(leafletData.leafletId, currentPage);
      }, [activityCount]);

    useEffect(() => {
        const initializeWebSocket = async () => {
            const client: Client = await connectWebSocketPageLock(getAccessTokenSilently, sendCurrentData, handleWebSocketMsgReceive);
            if (client) stompClientRef.current = client;
        };
        if (selectedFrameId && isSuccess) initializeWebSocket();
        return () => {
            if (stompClientRef.current) stompClientRef.current.deactivate();
        }
    }, [getAccessTokenSilently, selectedFrameId, isSuccess]);

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (newProductDrawerOpen) {
            openNewProductDrawer();
        } else {
            closeProductDrawer();
        }
    }, [newProductDrawerOpen]);

    useEffect(() => {
        if (newBPCODrawerOpen) {
            openNewBPCODrawer();
        } else {
            closeBPCODrawer();
        }
    }, [newBPCODrawerOpen]);

    useEffect(() => {
        if (
            data &&
            leafletData.headerData &&
            promotionTypesDictionary.data?.options &&
            themeSeasonDictionary.data?.options &&
            countryThemedWeeksDictionary.data?.options &&
            qualitySealsDictionary.data?.options
        ) {
            const derivedSeasonThemes: string[] = getDerivedThemeSeasons(leafletData?.headerData);
            const derivedThemedWeeks: string[] = getDerivedThemedWeeks(leafletData?.headerData);
            const derivedPromotionTypes: string[] = getDerivedPromotionTypes(leafletData?.headerData);

            setPromotions(
                transformPromotionsResponse(data).promotions.map((item) => ({
                    ...item,
                    themeSeasons: {
                        ...item.themeSeasons,
                        literals: item.themeSeasons.literals.map((literal) => ({
                            ...literal,
                            literalName: themeSeasonDictionary.data.options.find((o) => o.id === literal.literalId)?.name,
                        })),
                    },
                    countryThemedWeeks: {
                        ...item.countryThemedWeeks,
                        literals: item.countryThemedWeeks.literals.map((literal) => ({
                            ...literal,
                            literalName: countryThemedWeeksDictionary.data.options.find((o) => o.id === literal.literalId)?.name,
                        })),
                    },
                    promotionTypes: {
                        ...item.promotionTypes,
                        literals: item.promotionTypes.literals.map((literal) => ({
                            ...literal,
                            literalName: promotionTypesDictionary.data.options.find((o) => o.id === literal.literalId)?.name,
                        })),
                    },
                    qualitySeals: {
                        ...item.qualitySeals,
                        literals: item.qualitySeals.literals.map((literal) => ({
                            ...literal,
                            literalName: qualitySealsDictionary.data.options.find((o) => o.id === literal.literalId)?.name,
                        }))
                    },
                    derivedThemeSeasons: {
                        id: handyAttributes.themeSeason,
                        literals: derivedSeasonThemes ? derivedSeasonThemes.map((item) => ({literalId: item})) : []
                    },
                    derivedThemedWeeks: {
                        id: handyAttributes.countryThemedWeeks,
                        literals: derivedThemedWeeks ? derivedThemedWeeks.map((item) => ({literalId: item})) : []
                    },
                    derivedPromotionTypes: {
                        id: handyAttributes.promotionType,
                        literals: derivedPromotionTypes ? derivedPromotionTypes.map((item) => ({literalId: item})) : []
                    },
                }))
            );
            setFirstLoad(true);
        }
    }, [
        data,
        leafletData,
        themeSeasonDictionary.data,
        countryThemedWeeksDictionary.data,
        promotionTypesDictionary.data,
        qualitySealsDictionary.data,
    ]);

    useEffect(() => {
        if (isFirstLoad){
            onPromotionsNotModified(promotions);
            setFirstLoad(false);
        }
    },[promotions, isFirstLoad])

    const updateStateAndNotifyParent = (listOfPromotions:promotionUI[]) => {
        setPromotions(listOfPromotions);
        onPromotionsModified(listOfPromotions);
        onPromotionsNotModified(null);
    }

    const getDerivedThemeSeasons = (leafletHeaderData:headerData):string[] => {
        return leafletHeaderData?.pageThemeSeasons?.find((item) => item.pageNumber === currentPage)?.themeSeasonIds || leafletHeaderData?.themeSeasons;
    }

    const getDerivedThemedWeeks = (leafletHeaderData: headerData): string[] => {
        return leafletHeaderData?.pageCountryThemedWeeks?.find((item) => item.pageNumber === currentPage)?.countryThemedWeekIds || leafletHeaderData?.countryThemedWeeks;
    }

    const getDerivedPromotionTypes = (leafletHeaderData: headerData): string[] => {
        return leafletHeaderData?.pagePromotionTypes?.find((item) => item.pageNumber === currentPage)?.promotionTypeIds || leafletHeaderData?.promotionTypes;
    }

    const handleProductSelect = (selectedProduct: productSearchResultUI) => {
        if (selectedFrameId) {
            const newPromotions = [...promotions];
            const derivedSeasonThemes: string[] = getDerivedThemeSeasons(leafletData?.headerData);
            const derivedThemedWeeks: string[] = getDerivedThemedWeeks(leafletData?.headerData);
            const derivedPromotionTypes: string[] = getDerivedPromotionTypes(leafletData?.headerData);

            newPromotions.push(makePromotionOfProductSearch(selectedProduct, derivedSeasonThemes, derivedThemedWeeks, derivedPromotionTypes, getCurrencyForCountry(leafletData?.country)));
            updateStateAndNotifyParent(newPromotions);
            enqueueSnackbar(`${translate({id: 'b.productAdded'})}`, {variant: 'success', persist: false});
        }
    };

    const handlePromotionUpdate = (key, value, id) => {
        const newPromotions = [...promotions];
        const targetIndex = newPromotions.findIndex((promotion) => promotion.UIId === id);
        newPromotions[targetIndex][key] = value;
        updateStateAndNotifyParent(newPromotions);
    };

    const handleTableRowClick = (id: string) => {
        setPromotionDrawerOpen(true);
        setPromotionInDrawerId(id);
    };

    const handlePromotionDrawerClose = () => {
        setPromotionDrawerOpen(false);
        setPromotionInDrawerId('');
    };

    const handleBulkUpdate = (payload: bulkPromoUpdatePayload, promotionUIIids: string[], letItBreak: boolean = false) => {
        promotions.forEach((promotion) => {
            if (promotionUIIids.includes(promotion.UIId)) {
                for (const prop in payload) {
                    if (letItBreak) {
                        handlePromotionUpdate(prop, payload[prop], promotion.UIId);
                    }
                    // do not update if would cause error
                    else if (prop === 'promotionalPrice') {
                        if (payload.promotionalPrice && payload.regularPrice && payload.promotionalPrice < payload.regularPrice) { // payload has both prices and they're both valid (price<regular)
                            handlePromotionUpdate(prop, payload[prop], promotion.UIId);
                        } else if (promotion.regularPrice === null || payload[prop] < promotion.regularPrice) {
                            handlePromotionUpdate(prop, payload[prop], promotion.UIId);
                        }
                    } else if (prop === 'regularPrice') {
                        if (payload.promotionalPrice && payload.regularPrice && payload.promotionalPrice < payload.regularPrice) { // payload has both prices and they're both valid (price<regular)
                            handlePromotionUpdate(prop, payload[prop], promotion.UIId);
                        } else if (promotion.promotionalPrice === null || payload[prop] > promotion.promotionalPrice) {
                            handlePromotionUpdate(prop, payload[prop], promotion.UIId);
                        }
                    } else {
                        handlePromotionUpdate(prop, payload[prop], promotion.UIId);
                    }
                }
            }
        });
    };

    const handlePasteFromLocalStorage = (ids: string[], data: promotionUI) => {
        const newPromotions: promotionUI[] = [...promotions];
        const { promotionTypes, themeSeasons, countryThemedWeeks, qualitySeals, relativeDiscount, absoluteDiscount,
            mpu, promotionalPrice, regularPrice, giveAwayBundledProducts, validityDates } = data;

        promotions.forEach((promotion, idx) => {
            if (ids.includes(promotion.UIId)) {
                newPromotions[idx] = {
                    ...promotion,
                    promotionTypes, themeSeasons, countryThemedWeeks, qualitySeals, relativeDiscount, absoluteDiscount,
                    mpu, promotionalPrice, regularPrice, giveAwayBundledProducts, validityDates
                }
            }
        });
        updateStateAndNotifyParent(newPromotions);
    };

    const handlePromotionError = (error: boolean, promotionId: string) => handlePromotionUpdate('hasError', error, promotionId);

    const handlePromotionRemoval = (promotionId:string) =>{
        const newPromotions = promotions.filter((promotion) => promotion.UIId !== promotionId);
        updateStateAndNotifyParent(newPromotions);
    };

    const handlePromotionRemovalMultiple = (ids: string[]) => {
        const newPromotions = [...promotions].filter((promotion) => !ids.includes(promotion.UIId));
        updateStateAndNotifyParent(newPromotions);
    };

    const handlePromotionRemovalInDrawer = (promotionId: string) => {
        handlePromotionDrawerClose();
        handlePromotionRemoval(promotionId);
    };

    const handlePromotionClone = (id: string) => {
        const uniqueKey: string = uuidv4();
        const filterResult: promotionUI = promotions.find((item) => item.UIId === id);
        const clonedPromotions: promotionUI = _.cloneDeep(filterResult);
        if (clonedPromotions) {
            clonedPromotions.UIId = uniqueKey;
            delete clonedPromotions['id'];
            updateStateAndNotifyParent([...promotions, clonedPromotions]);
        }
    };

    const handlePromotionCloneMultiple = (ids: string[]) => {
        const matchedPromotions: promotionUI[] = promotions.filter((promotion) => ids.includes(promotion.UIId));
        const clonedPromotions: promotionUI[] = matchedPromotions.map((promotion) => _.cloneDeep(promotion));
        if (clonedPromotions?.length) {
            updateStateAndNotifyParent([...promotions].concat(clonedPromotions.map(({ id, ...rest }) => ({ ...rest, UIId: uuidv4() }))));
        }
    };

    const openNewProductDrawer = () => setProductDrawerProperties({...newProductDrawerProps, open: true});
    const openNewBPCODrawer = () => setBPCODrawerProperties({...newBPCODrawerProps, open: true});

    const openCloneProductDialog = (productId: string) => setProductDrawerProperties({
        clone: true,
        isNewProduct: false,
        open: true,
        productId
    });

    const openCloneBPCODialog = (BPCOId: string) => setBPCODrawerProperties({
        clone: true,
        isNewBPCO: false,
        open: true,
        BPCOId
    });

    const closeProductDrawer = () => {
        setProductDrawerProperties({...newProductDrawerProps, open: false});
        onProductDrawerClose();
    }

    const closeBPCODrawer = () => {
        setBPCODrawerProperties({...newBPCODrawerProps, open: false});
        onBPCODrawerClose();
    }

    const handleProductCreated = (createdProduct: productDetailsResponseModel, extendOrOverwrite: extendOrOverwritePromotionType) => {
        if (extendOrOverwrite.extend) {
            handleProductSelect(productDetails2productSearchResultUI(createdProduct));
        } else {
            const derivedSeasonThemes: string[] = getDerivedThemeSeasons(leafletData?.headerData);
            const derivedThemedWeeks: string[] = getDerivedThemedWeeks(leafletData?.headerData);
            const derivedPromotionTypes: string[] = getDerivedPromotionTypes(leafletData?.headerData);

            const updatePromotion: promotionUI[] = [...promotions];
            const index: number = updatePromotion.findIndex((promotion) => promotion.productId === extendOrOverwrite.overwriteProductId);

            const newPromotion: promotionUI = makePromotionOfProductSearch(productDetails2productSearchResultUI(createdProduct), derivedSeasonThemes, derivedThemedWeeks, derivedPromotionTypes, getCurrencyForCountry(leafletData?.country));

            updatePromotion[index] = {...newPromotion,
                promotionalPrice: updatePromotion[index].promotionalPrice,
                regularPrice: updatePromotion[index].regularPrice,
                relativeDiscount: updatePromotion[index].relativeDiscount,
                absoluteDiscount: updatePromotion[index].absoluteDiscount,
                mpu: updatePromotion[index].mpu,
                promotionTypes: updatePromotion[index].promotionTypes,
                themeSeasons: updatePromotion[index].themeSeasons,
                countryThemedWeeks: updatePromotion[index].countryThemedWeeks,
                qualitySeals:updatePromotion[index].qualitySeals
            };
            setPromotions(updatePromotion);
            enqueueSnackbar(`${translate({id: 'b.productAdded'})}`, {variant: 'success', persist: false});
        }
        closeProductDrawer();
    };

    const handleBPCOCreated = (createdBPCO: BPCOResponseModel, extendOrOverwrite: extendOrOverwritePromotionType) => {
        if (extendOrOverwrite.extend) {
            handleProductSelect(BPCODetails2productSearchResultUI(createdBPCO));
        } else {
            const derivedSeasonThemes: string[] = getDerivedThemeSeasons(leafletData?.headerData);
            const derivedThemedWeeks: string[] = getDerivedThemedWeeks(leafletData?.headerData);
            const derivedPromotionTypes: string[] = getDerivedPromotionTypes(leafletData?.headerData);

            const updatePromotion: promotionUI[] = [...promotions];
            const index: number = updatePromotion.findIndex((promotion) => promotion.bpcoId === extendOrOverwrite.overwriteProductId);

            const newPromotion: promotionUI = makePromotionOfProductSearch(BPCODetails2productSearchResultUI(createdBPCO), derivedSeasonThemes, derivedThemedWeeks, derivedPromotionTypes, getCurrencyForCountry(leafletData?.country));

            updatePromotion[index] = {...newPromotion,
                promotionalPrice: updatePromotion[index].promotionalPrice,
                regularPrice: updatePromotion[index].regularPrice,
                relativeDiscount: updatePromotion[index].relativeDiscount,
                absoluteDiscount: updatePromotion[index].absoluteDiscount,
                mpu: updatePromotion[index].mpu,
                promotionTypes: updatePromotion[index].promotionTypes,
                themeSeasons: updatePromotion[index].themeSeasons,
                countryThemedWeeks: updatePromotion[index].countryThemedWeeks,
                qualitySeals:updatePromotion[index].qualitySeals
            };
            setPromotions(updatePromotion);
            enqueueSnackbar(`${translate({id: 'b.bpcoAdded'})}`, {variant: 'success', persist: false});
        }
        closeBPCODrawer();
    };

    const handlePromotionsSuggestionClick = (promotionsForFrame: promotionSuggestionsForFrame, suggestionPosition: number) => {
        proposalsActivityTrackerPost.call(`${API_FRAME_PROPOSAL_ACTIVITY_TRACKER(selectedFrameId, PROPOSALS_ACTIVITY_TYPE.PROPOSAL_ACCEPTED)}?acceptedProposalPosition=${suggestionPosition}`);

        const derivedThemeSeasons = getDerivedThemeSeasons(leafletData.headerData);
        const derivedThemedWeeks = getDerivedThemedWeeks(leafletData.headerData);
        const derivedPromotionTypes = getDerivedPromotionTypes(leafletData.headerData);

        const newPromotions = addDerivedLeafletValues(
            transformPromotionsSuggestions(promotionsForFrame),
            derivedThemeSeasons,
            derivedThemedWeeks,
            derivedPromotionTypes
        );

        const updatedPromotions = [...promotions, ...newPromotions];

        updateStateAndNotifyParent(updatedPromotions);
        enqueueSnackbar(`${translate({ id: "b.productAdded" })}`, { variant: "success", persist: false });
    };

    const handlePromotionsSuggestionsClose = () => {
        proposalsActivityTrackerPost.call(API_FRAME_PROPOSAL_ACTIVITY_TRACKER(selectedFrameId, PROPOSALS_ACTIVITY_TYPE.PROPOSAL_REJECTED));
    }

    const handleToggleTemplate = (value: boolean) => {
        setShowTemplates(value);
        storeInLocalStorage(localStorageShowTemplates, JSON.stringify(value));
    };

    return (
        <>
            <LoadingOverlay show={isLoading}/>
            <PaperX>
                <div className="editFrameSearchFiltersContainer">
                    <ProductSearch countryId={leafletData.country}
                                   onProductClick={handleProductSelect}
                                   selectedItemsIds={promotions.map((item) => item.productId || item.bpcoId)}
                                   templatesExcluded={!showTemplates}/>
                    <ToggleTemplates value={showTemplates} onChange={handleToggleTemplate}/>
                </div>
            </PaperX>
            {mode === modes.DETAILS ? (
                <PaperX className="_fullHeight _fullWidth _scrollY">
                    {promotions.map((item) => (
                        <Promotion countryId={leafletData.country}
                                   data={item}
                                   key={item.UIId}
                                   onRemovePromotion={handlePromotionRemoval}
                                   onUpdatePromotion={handlePromotionUpdate}
                                   onPromotionError={handlePromotionError}
                                   onCloneProduct={openCloneProductDialog}
                                   onCloneBPCO={openCloneBPCODialog}
                                   onClonePromotion={handlePromotionClone}
                        />
                    ))}
                </PaperX>
            ) : (
                <PaperX className="_fullHeight _fullWidth _fullTable">
                    <PromotionsTable promotions={promotions}
                                     countryId={leafletData.country}
                                     onBulkUpdate={handleBulkUpdate}
                                     onCloneBPCO={openCloneBPCODialog}
                                     onCloneProduct={openCloneProductDialog}
                                     onClonePromotion={handlePromotionClone}
                                     onClonePromotionMultiple={handlePromotionCloneMultiple}
                                     onPasteFromLocalStorage={handlePasteFromLocalStorage}
                                     onRowClick={handleTableRowClick}
                                     onRemovePromotion={handlePromotionRemoval}
                                     onRemovePromotionMultiple={handlePromotionRemovalMultiple}
                    />
                </PaperX>
            )}
            <PromotionDrawer country={leafletData.country}
                             data={promotions.find((promotion) => promotion.UIId === promotionInDrawerId)}
                             onCloneBPCO={openCloneBPCODialog}
                             onCloneProduct={openCloneProductDialog}
                             onClonePromotion={handlePromotionClone}
                             onClose={handlePromotionDrawerClose}
                             onPromotionError={handlePromotionError}
                             onRemovePromotion={handlePromotionRemovalInDrawer}
                             onUpdatePromotion={handlePromotionUpdate}
                             open={promotionDrawerOpen}
            />
            <ProductDrawer {...productDrawerProperties}
                           onClose={closeProductDrawer}
                           onCreate={handleProductCreated}
            />
            <BPCODrawer {...BPCODrawerProperties}
                        onClose={closeBPCODrawer}
                        onCreate={handleBPCOCreated}
            />
            <PromotionsSuggestions forceOpen={isPromotionsSuggestionsOpen}
                                   onClick={(promotionsForFrame, suggestionPosition) => handlePromotionsSuggestionClick(promotionsForFrame, suggestionPosition)}
                                   countryId={leafletData.country}
                                   onClose={handlePromotionsSuggestionsClose}
                                   frameId={selectedFrameId}/>
            
            <InfoDialog message={translate({id: 'b.promptLockExpire'})}
                        onConfirm={onDialogConfirm}
                        open={isInfoDialogOpen}/>
        </>
    );
}

export default AdvertisementBrowserEditFramePromotions;