// convention:
// [prefix.]keyName
//
// use [a.] prefix when key is used throughout whole application for functional items like action buttons (yes, no close), statuses (loading) etc
// use [module/componentName] when key is used for app control purposes only in given component ex. 'upload leaflet'
// use [b] prefix if it is a business/domain knowledge term, doesn't matter if in single component or in many places

const pl = {
  'a.2many': 'Zbyt wiele wyników. Zawęź wyszukiwanie',
  'a.actions': 'Akcje',
  'a.active': 'Aktywny',
  'a.all': 'Wszystko',
  'a.add': 'Dodaj',
  'a.any': 'Każdy',
  'a.cancel': 'Anuluj',
  'a.clear': 'Wyczyść',
  'a.click2expand': 'Rozwiń',
  'a.close': 'Zamknij',
  'a.confirm': 'Potwierdź',
  'a.copy': 'Kopiuj',
  'a.details': 'Szczegóły',
  'a.edit': 'Edytuj',
  'a.editSelected': 'Edytuj wybrane',
  'a.erase': 'Wyczyść',
  'a.error': 'Błąd',
  'a.error2': 'Podczas przetwarzania twojego żądania wystąpił błąd',
  'a.errorNoAccess': 'Nie masz uprawnień do wykonania tej akcji',
  'a.exportExcel': 'Wyeksportuj do Excel',
  'a.framing': 'Tworzenie ramek',
  'a.inactive': 'Nieaktywny',
  'a.invalidAttr': 'Nieprawidłowa wartość atrybutu',
  'a.loading': 'Ładowanie',
  'a.new': 'Nowy',
  'a.newRadioSpot': 'Nowy spot radiowy',
  'a.newTvSpot': 'Nowy spot telewizyjny',
  'a.next': 'Dalej',
  'a.no': 'Nie',
  'a.of': 'z',
  'a.override': 'Nadpisanie',
  'a.overwrite': 'Zastąp',
  'a.paste': 'Wklej',
  'a.pasteSelected': 'Wklej w wybrane',
  'a.prev': 'Poprzednie',
  'a.progress': 'Postęp prac',
  'a.reason': 'Powód',
  'a.remove': 'Usuń',
  'a.removeSelected': 'Usuń wybrane',
  'a.replace': 'Zastąp',
  'a.replaceW': 'Zastąp używając',
  'a.right4context': 'Kliknij prawym przyciskiem myszy, aby wyświetlić menu kontekstowe',
  'a.save': 'Zapisz',
  'a.saveCopy': 'Zapisz kopię',
  'a.savePrompt': 'Czy na pewno chcesz zapisać zmiany?',
  'a.search': 'Szukaj',
  'a.searchLimit': 'Wyświetlanych 100 najlepszych wyników',
  'a.select': 'Wybierz',
  'a.selectAll': 'Wybierz wszystko',
  'a.settings': 'Ustawienia',
  'a.show': 'Pokaż',
  'a.submit': 'Prześlij',
  'a.summary': 'Podsumowanie',
  'a.tooltipClick2Activate': 'Kliknij aby aktywować',
  'a.tooltipClick2Deactivate': 'Kliknij by dezaktywować',
  'a.tooltipClck2Remove': 'Kliknij, aby usunąć',
  'a.value': 'Wartość',
  'a.valueFrom': 'Od',
  'a.valueTo': 'Do',
  'a.yes': 'Tak',
  'a.export': 'Eksport',
  'advertisementsOverview.allAdvertisements': 'Wszystkie promocje',
  'advertisementsOverview.manuallyCreatedAdvertisements': 'Manualnie utworzone promocje',
  'advertisementsOverview.connectedFrames': 'Inne połączone ramki',
  'advertisementsOverview.disconnectFrameMsg': 'Czy chcesz odłączyć ramkę od pozostałych powiązanych ramek?',
  'advertisementsOverview.deleteFrame': 'Usuń ramkę',
  'advertisementsOverview.deleteFrameConfirm': 'Czy aby na pewno życzysz sobie usunąć wybraną ramkę wraz z przypisanymi do niej promocjami?',
  'advertisementsOverview.deleteFrameErr': 'Nie udało się usunąć ramki',
  'advertismentsOverview.disconnectPage': 'Strona rozłączenia',
  'advertisementsOverview.disconnectPageMsg': 'Czy naprawdę chcesz odłączyć tę stronę od wszystkich duplikatów?',
  'advertisementsOverview.disconnectPageSucc': 'Strona została pomyślnie rozłączona.',
  'advertisementsOverview.disconnectPageErr': 'Nieudane rozłączenie strony.',
  'advertisementsOverview.getIfDuplicatesErr': 'Sprawdzanie błędów dla duplikatów.',
  'advertisementsOverview.fetchPromoErr': 'Błąd wczytywania promocji',
  'advertisementsOverview.overview': 'Przegląd ogłoszeń (gazetki/prasa)',
  'advertisementsOverview.updateErr': 'Błąd aktualizowania promocji',
  'advertisementsOverview.updateSucc': 'Promocje zaktualizowane',
  'advertisingArea.cantSetAsRep': 'Ta filia jest już ustawiona jako reprezentatywna dla innego obszaru',
  'advertisingArea.create': 'Utwórz nowy obszar reklamowy',
  'advertisingArea.err': 'Błąd wczytywania obszaru reklamowego',
  'advertisingArea.invalidRepresentative': 'Wybrana filia reprezentatywna jest już w użyciu',
  'advertisingArea.name': 'Nazwa obszaru reklamowego',
  'advertisingArea.saveErr': 'Błąd zapisywania obszaru reklamowego',
  'advertisingArea.setAsRep': 'Ustaw jako filię reprezentatywną',
  'advertisingArea.succ': 'Zapisano obszar reklamowy',
  'advertisingAreaList.err': 'Błąd wczytywania obszarów reklamowych',
  'agGrid.pageSize': 'Liczba wierszy',
  'agGrid.reset': 'Zresetuj kolumny',
  'attributes.activeAssignment': 'Aktywne przypisanie',
  'attributes.err': 'Błąd wczytywania cech',
  'attributes.label': 'Cechy',
  'attributes.literalRemoved': 'Wartość cechy usunięta',
  'attributes.name': 'Nazwa cechy',
  'attributes.new': 'Utwórz nową cechę',
  'attributes.savePrompt': 'Wszelkie dokonane zmiany dotyczące aktywacji/dezaktywacji przypisanych atrybutów i wartości będą zastosowane do wszystkich powiązanych z nimi produktów.',
  'attributes.saveErr': 'Błąd zapisywania cechy',
  'attributes.selectedUnits': 'Wybrane elementy',
  'attributes.step1': 'Ustaw nazwę cechy',
  'attributes.step2': 'Wybierz typ cechy',
  'attributes.step3': 'Ustaw zawartość',
  'attributes.succ': 'Cecha zapisana',
  'attributes.type': 'Typ cechy',
  'attributes.typeBrickDependentSet': 'Wartości cech zależne od modułu',
  'attributes.typeNumUnit': 'Numer z jednostką',
  'attributes.typeRangeUnit': 'Zasięg z jednostką',
  'attributes.typeSet': 'Zestaw wartości cech',
  'attributes.typeTxt': 'Dowolny tekst',
  'b.absoluteDiscount': 'Rabat całkowity',
  'b.addMainDescription': 'Dodaj opis główny',
  'b.addAlternativeDescription': 'Dodaj opis alternatywny',
  'b.address': 'Adres',
  'b.advertisementProposals': 'Promocja/Sugestia',
  'b.advertisingArea': 'Obszar reklamowy',
  'b.advertisingAreas': 'Obszary reklamowe',
  'b.alternativeDescription': 'Opisy alternatywne',
  'b.banner': 'Baner',
  'b.banners': 'Banery',
  'b.bpco': 'MKPO',
  'b.bpcoDescription': 'Opis MGO',
  'b.bpcoAdded': 'Dodano MGO',
  'b.branches': 'Filie',
  'b.brand': 'Marka',
  'b.brands': 'Marki',
  'b.capacity': 'Pojemność',
  'b.capacityUnit': 'Jednostka pojemności',
  'b.categories': 'Kategorie produktów',
  'b.category': 'Kategoria',
  'b.categoryAttributes': 'Cechy kategorii',
  'b.categoryBrick': 'Moduł',
  'b.categoryCharacteristics': 'Charakterystyka kategorii',
  'b.categoryClass': 'Klasa',
  'b.categoryFamily': 'Rodzina',
  'b.categorySegment': 'Segment',
  'b.characteristics': 'Charakterystyka',
  'b.city': 'Miasto',
  'b.contentOfTradingUnit': 'Zawartość opakowania',
  'b.contentOfTradingUnitUnit': 'Zawartość opakowania zbiorczego',
  'b.country': 'Kraj',
  'b.countryThemedWeek': 'Tydzień tematyczny',
  'b.dataLanguage': 'Język danych',
  'b.deprecated': 'Dezaktywowany',
  'b.deprecatedCharacteristics': 'Cechy dezaktywowane',
  'b.descr': 'Opis',
  'b.descriptionLangErr': 'Dla każdego języka może istnieć tylko jeden główny opis',
  'b.descriptionReplaced': 'Opis został zastąpiony',
  'b.descriptionSaveErr': 'Błąd zapisywania opisu',
  'b.descriptionSaveSucc': 'Opis został zapisany',
  'b.enterHeaderData': 'Wprowadź dane nagłówka',
  'b.fileName': 'Nazwa pliku',
  'b.franchiseOwner': 'Właściciel franczyzy',
  'b.franchiseOwners': 'Właściciele franczyzy',
  'b.general': 'Informacje ogólne',
  'b.gtin': 'GTIN',
  'b.houseNumber': 'Numer domu',
  'b.industry': 'Przemysł',
  'b.isMarkantRetailPartner': 'Partner handlowy Markant',
  'b.issuesType': 'Typ zgłoszenia',
  'b.keyAccount': 'Klient kluczowy',
  'b.latitude': 'Szerokość geograficzna',
  'b.leafletValidity': 'Ważność ulotki',
  'b.location': 'Lokalizacja',
  'b.longitude': 'Długość geograficzna',
  'b.mainDescription': 'Opisy główne',
  'b.market': 'Rynek',
  'b.media': 'Media nadawcze',
  'b.mediumType': 'Typ publikacji',
  'b.mpu': 'Minimalna liczba sztuk',
  'b.name': 'Nazwa',
  'b.nielsenRegion': 'Regiony Nielsena',
  'b.pageValidity': 'Ważność strony',
  'b.postCode': 'Kod pocztowy',
  'b.price': 'Cena',
  'b.priceGross': 'Cena brutto',
  'b.priceNet': 'Cena netto',
  'b.product': 'Produkt',
  'b.productAdded': 'Produkt dodany',
  'b.productCopy': 'Kopiuj produkt',
  'b.productDescription': 'Opis produkt',
  'b.productLine': 'Linia produktowa',
  'b.promotionCountryWeek': 'Tydzień tematyczny',
  'b.promotionQS': 'Tydzień tematyczny na stronie',
  'b.promotionSeasonTheme': 'Sezon/ temat',
  'b.promotionType': 'Rodzaj promocji',
  'b.promotionalPrice': 'Cena promocyjna',
  'b.promptLockExpire': 'Blokada strony wygasła z powodu braku aktywności. Za chwilę zostaniesz przekierowany do poprzedniej strony.',
  'b.region': 'Region',
  'b.regularPrice': 'Cena regularna',
  'b.relativeDiscount': 'Rabat względny',
  'b.reportedBy': 'Zgłoszone przez',
  'b.representativeBranch': 'Filia reprezentatywna',
  'b.retailer': 'Klient',
  'b.retailerHQ': 'Siedziba przedsiębiorstwa handlowego',
  'b.retailers': 'Klienci',
  'b.street': 'Ulica',
  'b.supplier': 'Dostawca',
  'b.suppliers': 'Dostawcy',
  'b.taxRate': 'Stawka podatkowa',
  'b.typeOfBranch': 'Typ filii',
  'b.typeOfBranchFranchise': 'Franczyza',
  'b.typeOfBranchStd': 'Standard',
  'b.typeOfPackage': 'Rodzaj opakowania',
  'b.unit': 'Jednostka',
  'b.userInterfaceLanguage': 'Język interfejsu',
  'b.variety': 'Wariant',
  'b.validFrom': 'Ważny od',
  'b.validTo': 'Ważny do',
  'banner.duplicate': 'Ten baner już istnieje',
  'banner.err': 'Błąd wczytywania banerów',
  'banner.publishWithValidity': 'Opublikuj z okresem ważności',
  'banner.saveErr': 'Błąd zapisywania banerów',
  'banner.succ': 'Baner zapisany',
  'banner.tooltipPublishWithValidity': 'Wszystkie nośniki reklamowe dla banera oznaczonego tą flagą będą opublikowane w systemie MPM dopiero w dniu początku ważności nośnika. Nie ma znaczenia kiedy nośnik został wgrany lub wprowadzony.',
  'bpco.createCopy': 'Stwórz kopię MGO',
  'bpco.duplicate': 'Taka sama marka-produkt-kategoria-obiekt już istnieje',
  'bpco.err': 'Błąd wczytywania maki-grupy produktów-obiektów',
  'bpco.errorFetchingCreated': 'Błąd wczytywania utworzonego opisu. Użyj wyszukiwarki aby go odnaleźć.',
  'bpco.saveErr': 'Błąd zapisywania marki-grupy produktów-obiektu',
  'bpco.succ': 'Zapisano markę-grupę produktów-obiekt',
  'branchDetails.cantDeactivate': 'Nie można dezaktywować. Filia jest reprezentatywna dla obszaru reklamowego.',
  'branchDetails.err': 'Błąd wczytywania szczegółów filii',
  'branchDetails.isRep': 'Nie można dezaktywować - filia jest reprezentatywna dla',
  'branchDetails.saveErr': 'Błąd zapisywania szczegółów filii',
  'branchDetails.succ': 'Zapisano szczegóły filii',
  'branchesOf': 'Filie',
  'branchesSelector.avail': 'Dostępne filie',
  'branchesSelector.selected': 'Zaznaczone filie',
  'brand.already': 'Ta marka już jest używana',
  'brand.create': 'Utwórz nową markę',
  'brand.createStructured': 'Utwórz nową markę złożoną',
  'brand.duplicate': 'Marka już istnieje',
  'brand.duplicateConfirmation1': 'Znaleziono duplikat lub podobną markę.',
  'brand.duplicateConfirmation2': 'Czy nadal chcesz stworzyć nową markę??',
  'brand.duplicateFound': 'Duplikaty / podobne marki',
  'brand.edit': 'Edytuj markę',
  'brand.editStructured': 'Edytuj markę złożoną',
  'brand.err': 'Błąd wczytywania marki',
  'brand.errs': 'Błąd wczytywania marek',
  'brand.main': 'Marka główna',
  'brand.name': 'Nazwa marki',
  'brand.saveErr': 'Błąd zapisywania marki',
  'brand.secondary': 'Marki podstawowe',
  'brand.selectSec': 'Wybierz markę podstawową',
  'brand.succ': 'Marka zapisana',
  'brand.struct': 'Marka złożona',
  'category.alreadySelected': 'Ta cecha jest już wybrana',
  'category.alternative': 'Kategoria alternatywna',
  'category.attrUpdateErr': 'Błąd aktualizowania atrybutów kategorii',
  'category.bName': 'Nazwa modułu',
  'category.cName': 'Nazwa klasy',
  'category.confirmation': 'Elementy zależne będą przypisane do zadeklarowanej alternatywy.',
  'category.createErr': 'Błąd tworzenia kategorii',
  'category.createSucc': 'Kategoria utworzona',
  'category.err': 'Błąd wczytywania kategorii',
  'category.expandAll': 'Rozwiń wszystkie',
  'category.expandRow': 'Rozwiń wiersz',
  'category.fName': 'Nazwa rodziny',
  'category.newBrick': 'Utwórz moduł',
  'category.newClass': 'Utwórz klasę',
  'category.newFamily': 'Utwórz rodzinę',
  'category.newSegment': 'Utwórz segment',
  'category.parent': 'Kategoria nadrzędna',
  'category.requireLiterals': 'Atrybut wymaga przypisania wartości cech',
  'category.setupBrickDependentAttr': 'Przypisz wartości cech dla {attributeName}',
  'category.sName': 'Nazwa segmentu',
  'category.updateErr': 'Błąd zapisywania kategorii',
  'category.updateSucc': 'Kategoria zaktualizowana',
  'dictionary.dict': 'Słownik',
  'dictionary.err': 'Błąd wczytywania słownika',
  'dictionary.new': 'Dodaj nowy element do tego słownika',
  'dictionary.saveErr': 'Błąd aktualizowania słownika',
  'dictionary.succ': 'Słownik zaktualizowany',
  'dictionaryElement.alt': 'Wartość alternatywna',
  'dictionaryElement.alternative': 'Wartość alternatywna',
  'dictionaryElement.err': 'Błąd wczytywania elementu słownika',
  'dictionaryElement.info': 'Kliknij dwukrotnie, aby edytować',
  'dictionaryElement.info2': 'Wymagany język angielski i co najmniej jedno inne tłumaczenie',
  'dictionaryElement.lang': 'Język',
  'dictionaryElement.trans': 'Tłumaczenie',
  'fileUpload.chooseFiles': 'Wybierz pliki',
  'fileUpload.formatWarning': 'Pliki {files} mają nieprawidłowy format',
  'fileUpload.or': 'lub',
  'fileUpload.preventIdenticalRecognition': 'Zablokuj wykrywanie identycznych stron',
  'fileUpload.selectedFile': 'Wybrany plik',
  'fileUpload.uploadFilePlaceholder': 'Przeciągnij i upuść plik',
  'filters': 'Filtry',
  'filters.clear': 'Wyczyść filtry',
  'fixReportedFrames.cantSubmitEmpty': 'Na stronie znajdują się ramki. Nie może zostać oznaczona jako pusta',
  'frame.clear': 'Wyczyść wszystkie ramki',
  'frame.empty': 'Pusta strona',
  'frame.emptyConfirm': 'Nie utworzono ramek. Czy chcesz oznaczyć ją jako pustą?',
  'frame.err': 'Błąd zapisywania ramek',
  'frame.fitToWindow': 'Dopasuj do okna',
  'frame.reviseFramesPrevPage': 'Popraw ramki na poprzedniej stronie',
  'frame.noPages': 'Brak stron do zaramkowania',
  'frame.skip': 'Pomiń stronę',
  'frame.skipped': 'Strona pominięta',
  'frame.submit': 'Zatwierdź ramki',
  'frame.succ': 'Ramki zapisane',
  'frame.validFrom': 'Ramka ważna od',
  'frame.validTo': 'Ramka ważna do',
  'frame.validity': 'Ważność ramki',
  'frameDescription.addFrameValidity': 'Dodaj ważność ramki',
  'frameDescription.apply2selected': 'Zastosuj dla wybranych promocji',
  'frameDescription.confirmSelectedRemoval': 'Usunąć {count} wybrane promocje?',
  'frameDescription.eraseAction': 'Wyczyść wartości wybranych promocji',
  'frameDescription.err': 'Błąd wczytywania danych ramki',
  'frameDescription.errCantReuse': 'Błąd z wykorzystaniem stworzonego produktu. Użyj wyszukiwania by go dodać do ramki.',
  'frameDescription.errImg': 'Błąd wczytywania obrazka ramki',
  'frameDescription.errNoFrame': 'Brak dostępnej ramki',
  'frameDescription.fetchPromoSugErr': 'Błąd szukania podobnych promocji',
  'frameDescription.fetchPromoSugNothing': 'Nie znaleziono podobnych promocji',
  'frameDescription.fetchPromoSugTitle': 'Propozycje',
  'frameDescription.framePrepNotFinished': 'Przygotowanie ramki nie zostało ukończone. Ponów operację później lub opisz ramkę bez propozycji promocji.',
  'frameDescription.noProposalsForFrame': 'Brak dostępnych propozycji promocji. Kontynuuj opisywanie ramki bez propozycji.',
  'frameDescription.report': 'Zgłoś ramkę',
  'frameDescription.reportAdvertisement': 'Zgłoś promocje',
  'frameDescription.reported': 'Ramka zgłoszona',
  'frameDescription.reportReason1': 'Problem z ramką',
  'frameDescription.reportReason2': 'Problem z opisaniem ramki',
  'frameDescription.reportedAdvertisement': 'Promocja/Sugestia zgłoszona',
  'frameDescription.saveErr': 'Błąd zapisywania promocji',
  'frameDescription.skip': 'Pomiń ramkę',
  'frameDescription.skipped': 'Ramka pominięta',
  'frameDescription.succ': 'Promocja utworzona pomyślnie',
  'franchiseOwner.create': 'Utwórz nowego właściciela franczyzy',
  'franchiseOwner.err': 'Błąd wczytywania właściciela franczyzy',
  'franchiseOwner.name': 'Nazwa właściciela franczyzy',
  'franchiseOwner.saveErr': 'Błąd podczas zapisywania właściciela franczyzy',
  'franchiseOwner.succ': 'Właściciel franczyzy zapisany',
  'franchiseOwnersList.err': 'Błąd wczytywania właścicieli franczyzy',
  'gtin.duplicatedGtins': 'Duplikaty GTINów',
  'gtin.productLocked': 'Ten produkt jest zablokowany przez innego użytkownika',
  'gtin.errorUploadingFile': 'Błąd przesyłania pliku',
  'gtin.errorUploadingFileExtension': 'Błąd: złe rozszerzenie pliku. Załaduj plik .csv',
  'gtin.importGtins': 'Importuj GTINy',
  'gtin.incorrectGtins': 'Nieprawidłowe GTINy',
  'gtin.incorrectProducts': 'Nieprawidłowe produkty',
  'gtin.numberOfAdjustedProducts': 'Produkty ze zmienionym numerem GTIN',
  'gtin.productsWithoutGtins': 'Produkty bez numerów GTIN',
  'gtin.recordInFile': 'Ilość wyników w pliku',
  'headerData.addPageValidity': 'Dodaj ważność strony',
  'headerData.addPageCountryThemedWeeks': 'Dodaj tydzień tematyczny na stronie',
  'headerData.addPageSeasonTheme': 'Dodaj sezon / temat strony',
  'headerData.addPagePromotionType': 'Dodaj rodzaj promocji na stronie',
  'headerData.countryThemedWeeksPerPage': 'Tydzień tematyczny na stronie',
  'headerData.copied': 'Dane nagłówka przechowywane w schowku',
  'headerData.copiedErr': 'Brak danych w schowku',
  'headerData.duplicateConfirm': 'Czy chcesz je zapisać mimo to?',
  'headerData.duplicateLeaflet': 'Ulotka z takimi samym danymi nagłówka już istnieje.',
  'headerData.duplicateLeafletCountryThemedWeeeks': 'Błąd tygodnia tematycznego na stronie - ta sama strona użyta wielokrotnie: ',
  'headerData.duplicateLeafletPromotionTypePages': 'Błąd rodzaju promocji na stronie - ta sama strona użyta wielokrotnie: ',
  'headerData.duplicateLeafletThemeSeasonsPages': 'Błąd sezon / temat strony - wielokrotnie użyto tej samej strony: ',
  'headerData.duplicateLeafletValidityPages': 'Błąd ważności strony - wielokrotnie użyto tej samej strony: ',
  'headerData.err': 'Błąd wczytywania ulotki',
  'headerData.errLeafletValidityPages': 'Błąd ważności strony - brakuje daty',
  'headerData.errLocked': 'Ta ulotka jest zablokowana przez innego użytkownika',
  'headerData.invalidPriceMode': 'Nieprawidłowy rodzaj ceny',
  'headerData.leafletValidF': 'Ulotka ważna od',
  'headerData.leafletValidT': 'Ulotka ważna do',
  'headerData.pages': 'Strony',
  'headerData.pgValidF': 'Strona ważna od',
  'headerData.pgValidT': 'Strona ważna do',
  'headerData.promotionTypesPerPage': 'Rodzaj promocji na stronie',
  'headerData.saveErr': 'Błąd zapisywania ulotki',
  'headerData.seasonThemePerPage': 'Sezon / temat poszczególnych stron',
  'headerData.selectBranches': 'Wybierz filie',
  'headerData.selectByAdvArea': 'Wybierz po obszarze reklamowym',
  'headerData.succ': 'Szczegóły ulotki zapisane',
  'headerData.useAdvArea': 'Użyj filii obszaru {areaName}',
  'headerData.useComboAdvAreas': 'Użyj filii wybranych obszarów',
  'headerData.validityPerPage': 'Ważność poszczególnych stron',
  'hierarchy.banners': 'Baner {retailerName}',
  'hierarchy.confirmation1': ' będzie dezaktywowane.',
  'hierarchy.confirmation2': 'Powiązane elementy również zostaną dezaktywowane: ',
  'hierarchy.createKeyAccount': 'Utwórz klienta kluczowego',
  'hierarchy.createRetailer': 'Utwórz klienta',
  'hierarchy.emptyList': 'Nie znaleziono elementów',
  'hierarchy.keyAccounts': 'Klienci kluczowi',
  'hierarchy.retailers': 'Sieci handlowe dla {keyAccountName}',
  'industries.err': 'Błąd wczytywania branż',
  'industrySelector.err': 'Błąd wczytywania branż',
  'keyAccount.duplicate': 'Ten klient kluczowy już istnieje',
  'keyAccount.saveErr': 'Błąd zapisywania klienta kluczowego',
  'keyAccount.succ': 'Klient kluczowy zapisany',
  'language.bg': 'Bułgarski',
  'language.cs': 'Czechy',
  'language.de': 'Niemiecki',
  'language.de_AT': 'Austriacki niemiecki',
  'language.de_CH': 'Szwajcarski niemiecki',
  'language.en': 'Angielski',
  'language.fr': 'Francuski',
  'language.hr': 'Chorwacki',
  'language.hu': 'Węgierski',
  'language.it': 'Włoski',
  'language.nl': 'Holenderski',
  'language.pl': 'Polski',
  'language.ro': 'Rumuński',
  'language.sk': 'Słowacki',
  'language.sl': 'Słoweński',
  'leafletBrowser.goToFrame': 'Idź do ramki',
  'leafletBrowser.report': 'Zgłoś stronę',
  'leafletBrowser.reported': 'Strona zgłoszona', 
  'leafletPage.error': 'Błąd ładowania obrazu strony',
  'leafletStatus.described': 'Opisane',
  'leafletStatus.desribingInProgress': 'Opisywanie w toku',
  'leafletStatus.done': 'Gotowe',
  'leafletStatus.framed': 'Obramowane',
  'leafletStatus.framesDescribed': 'Ramki opisane',
  'leafletStatus.paged': 'Stronicowane',
  'leafletStatus.uploaded': 'Wgrane',
  'leafletUpload.clearAll': 'Wyczyść wszystko',
  'leafletUpload.clearUploaded': 'Wyczyść wgrane',
  'leafletUpload.fileSize': 'Rozmiar pliku',
  'leafletUpload.pressAdvertisement': 'Reklama prasowa',
  'leafletUpload.statusCancelled': 'Przesyłanie anulowane',
  'leafletUpload.statusLoaded': 'Przesłane',
  'leafletUpload.statusLoading': 'Przesyłanie w toku',
  'leafletUpload.statusNew': 'W kolejce',
  'leafletUpload.uploadFailed': 'Podczas przesyłania pliku wystąpił błąd',
  'leafletUpload.uploadSuccess': 'Pliki przesłane pomyślnie',
  'leafletUploadNavButton.leafletUploadCTA': 'Wgraj media drukowane',
  'literals.activeLiterals': 'Aktywne wartości cech',
  'literals.duplicate': 'Ta wartość już istnieje na liście',
  'literals.err': 'Błąd wczytywania wartości cech',
  'literals.errCreate': 'Błąd tworzenia wartości cech',
  'literals.errUpdate': 'Błąd aktualizacji wartości cech',
  'literals.inactiveLiterals': 'Nieaktywne wartości cech',
  'literals.label': 'Wartości cech',
  'literals.new': 'Utwórz nową wartość cechy',
  'literals.succCreate': 'Wartość cechy utworzona',
  'literals.succUpdate': 'Wartość cechy zaktualizowana',
  'log.copyAll': 'Skopiuj wszystko',
  'log.copyToday': 'Skopiuj dzisiejsze wydarzenia',
  'log.date': 'Data wydarzenia',
  'log.details': 'Szczegóły wydarzenia',
  'log.header': 'Dziennik zdarzeń',
  'log.removeAll': 'Usuń wszystkie wpisy',
  'masterData.BPCO': 'Marka-kategoria produktu-obiekt',
  'masterData.attributeError': 'Błąd ładowania atrubutu',
  'masterData.basicMasterData': 'Podstawowe dane',
  'masterData.dictionaries': 'Słowniki',
  'masterData.productLines': 'Linie produktowe',
  'masterData.products': 'Produkty',
  'masterData.retailerHierarchy': 'Klient kluczowy / Przedsiębiorstwo handlowe / Baner',
  'media.chooseSpotFile': 'Wybierz plik do wgrania',
  'media.copyFileName': 'Kopiuj nazwę pliku',
  'media.delete': 'Usuń spot',
  'media.deleteErr': 'Błąd usuwania spotu',
  'media.deleteFile': 'Usuń plik',
  'media.deletePrompt': 'Czy na pewno chcesz usunąć ten spot?',
  'media.deleteSucc': 'Spot usunięty',
  'media.describeMedia': 'Opisz media',
  'media.errNoMedia': 'Brak dostępnych mediów',
  'media.mediaFileFetchErr': 'Błąd podczas pobierania mediów',
  'media.spotFetchErr': 'Błąd wczytywania danych spotu',
  'media.radioSpotDescribe': 'Opisz spot radiowy',
  'media.tvSpotDescribe': 'Opisz spot telewizyjny',
  'media.spotDescribe': 'Opisz spot',
  'media.spotFilterStatus': 'Status spotu: ',
  'media.spotLanguage': 'Język spotu',
  'media.radioSpotPlayAudio': 'Odtwórz plik',
  'media.spotSaveErr': 'Błąd zapisywania spotu',
  'media.spotSucc': 'Spot zapisany',
  'media.radioSpotVerify': 'Zweryfikuj spot radiowy',
  'media.tvSpotVerify': 'Zweryfikuj spot telewizyjny',
  'media.spotVerify': 'Zweryfikuj spot',
  'media.spotLocked': 'Ten spot jest zablokowany przez innego użytkownika',
  'media.spotSaveInfo': 'Aby zapisać transkrypt, należy wypełnić wszystkie wymagane pola.',
  'media.statusDone': 'Gotowe',
  'media.statusPending': 'Oczekuje na propozycje',
  'media.statusRegistered': 'Wymagana weryfikacja',
  'media.statusVerified': 'Gotowy do opisania',
  'media.transcriptProposals': 'Propozycja transkrypcji',
  'media.transcription': 'Transkrypcja',
  'nav.goTo': 'Przejdź do...',
  'nav.advertisement': 'Reklama',
  'nav.frame': 'Ramka',
  'nav.framingCTA': 'Rozpocznij tworzenie ramek',
  'nav.leaflet': 'Media drukowane',
  'nav.leafletAutomation': 'Automatyczne pobieranie ulotek',
  'nav.masterData': 'Dane podstawowe',
  'nav.mediumSpot': 'Przegląd spotów',
  'nav.mediumVerification': 'Sprawdzanie spotów',
  'nav.myAdvertisements': 'Moje reklamy',
  'nav.overviewAdvertisements': 'Przegląd ogłoszeń (gazetki/prasa)',
  'nav.overviewAdvertisementsPromo': 'Przegląd ogłoszeń (promocja)',
  'nav.overviewFrames': 'Przegląd ramek',
  'nav.overviewQuality': 'Statystyka',
  'nav.overviewAdvertisementsSpot': 'Przegląd ogłoszeń (radio/tv)',
  'nav.quality': 'Jakość',
  'nav.qualityBrowse': 'Zgłoszone problemy',
  'nav.showLeaflet': 'Pokaż ulotkę',
  'page.locked': 'Ta strona jest zablokowana przez innego użytkownika',
  'printMediaOverview.createdBy': 'Stworzony przez',
  'printMediaOverview.creationDate': 'Utworzony',
  'printMediaOverview.currentUsers': 'Aktywni użytkownicy',
  'printMediaOverview.deleteConfirmation': 'Czy naprawdę chcesz usunąć tę ulotkę?',
  'printMediaOverview.deleteInstruction': 'Aby potwierdzić, wpisz "DELETE".',
  'printMediaOverview.delete': 'Usuń ulotkę',
  'printMediaOverview.deleteSucc': 'Ulotka została pomyślnie usunięta',
  'printMediaOverview.deleteErr': 'Wystąpił błąd podczas usuwania ulotki',
  'printMediaOverview.describeFrame': 'Opisz ramkę',
  'printMediaOverview.describedFrames': 'Opisanych ramek',
  'printMediaOverview.editFrames': 'Edytuj ramki',
  'printMediaOverview.editHeaderData': 'Edytuj dane nagłówka',
  'printMediaOverview.filter': 'Filtruj ulotki po banerze:',
  'printMediaOverview.filterBanner': 'Pokaż ulotki dla banera: ',
  'printMediaOverview.filterLanguage': 'Pokaż ulotki z językiem: ',
  'printMediaOverview.filterStatus': 'Pokaż ulotki w statusie: ',
  'printMediaOverview.headerDataCTA': 'Wprowadź dane nagłówka',
  'printMediaOverview.leafletDescribed': 'Gotowe do tworzenia ramek',
  'printMediaOverview.leafletFramed': 'Gotowe do opisania',
  'printMediaOverview.leafletPaged': 'Wymagane dane nagłówka',
  'printMediaOverview.leafletReady': 'Zakończone',
  'printMediaOverview.leafletType': 'Typ ulotki',
  'printMediaOverview.leafletUploaded': 'Stronicowanie w toku',
  'printMediaOverview.modifiedBy': 'Zmodyfikowany przez',
  'printMediaOverview.noProgressData': 'Brak dostępnych danych dla postępu prac',
  'printMediaOverview.numberOfPages': 'Liczba stron',
  'printMediaOverview.overview': 'Przegląd mediów drukowanych',
  'printMediaOverview.reportedFrames': 'Zgłoszone ramki',
  'printMediaOverview.reportedPages': 'Zgłoszone strony',
  'printMediaOverview.selectLanguage': 'Język ulotki',
  'printMediaOverview.status': 'Status',
  'printMediaOverview.totalFrames': 'Wszystkich ramek',
  'printMediaOverview.validityDates': 'Daty ważności',
  'product.addGtin': 'Dodaj GTIN',
  'product.bulk': 'Zbiorcza aktualizacja produktów',
  'product.bulkDescription': 'Zbiorcza aktualizacja opisów produktów',
  'product.bulkDescriptionHelp': 'Atrybuty widoczne na liście pochodzą z nowo wybranej grupy towarów. Przynajmniej jeden z wybranych produktów pochodzi z modułu nie powiązanego wcześniej z daną cechą. Uzupełnij brakujące wartości. Pozostałe cechy będące w zgodzie z obecną grupą towarów zostaną automatycznie przeniesione.',
  'product.bulkDuplicates': 'W wyniku aktualizacji powstaną duplikaty produktów ({count}). Zostaną one dezaktywowane i zastąpione. Tej akcji nie można cofnąć.',
  'product.bulkSucc': 'Produkty zostały zaktualizowane pomyślnie',
  'product.bulkSucc2': 'Aktualizuję wyszukiwarkę. Może to chwilę potrwać...',
  'product.bulkSupplier': 'Grupowe przypisanie marki/dostawcy',
  'product.bulkSupplierConfirm': 'Zamierzasz ustawić {sName} jako dostawcę dla {count} produktów.',
  'product.bulkUpdate': 'Zaktualizuj {count} produktów',
  'product.bulkUpdateDescription': 'Zaktualizuj {count} produktów używając następującego opisu',
  'product.chooseDescription': 'Wybierz opis produktu',
  'product.confirmReplace': 'Potwierdź zastąpienie produktu. Tej akcji nie można cofnąć.',
  'product.confirmReplaceDescription': 'Potwierdź zastąpienie opisu. Tej akcji nie można cofnąć.',
  'product.confirmUpdate': '{count} produktów zostanie zaktualizowanych',
  'product.copyAllGtins': 'Kopiuj wszystkie GTINy',
  'product.copyThisGtin': 'Kopiuj ten GTIN',
  'product.createCopy': 'Stwórz kopię produktu',
  'product.duplicate': 'Ten sam produkt już istnieje',
  'product.duplicateConfirmPrompt': 'Ten sam produkt już istnieje. Czy chcesz je połączyć?',
  'product.err': 'Błąd wczytywania produktu',
  'product.errImg': 'Błąd pobierania obrazów produktu.',
  'product.errAdding': 'Błąd dodawania produktu',
  'product.fixReported': 'Oznacz produkt jako naprawiony',
  'product.gtinAdded': 'Dodano GTIN',
  'product.gtinErr': 'Błędny GTIN',
  'product.gtinExists': 'Ten GTIN jest już używany',
  'product.gtinFetchErr': 'Błąd pobierania szczegółów GTIN',
  'product.gtinRemoved': 'Usunięto GTIN',
  'product.gtinVerified': 'Zweryfikowany',
  'product.hasIssues': 'Ten produkt ma nierozwiązane problemy',
  'product.hideImages': 'Ukryj obrazy',
  'product.isTemplate': 'Szablon produktu',
  'product.issuesResolved': 'Problemy produktu zostały naprawione',
  'product.listErr': 'Błąd wczytywania listy produktów',
  'product.noImgAvailable': 'Brak dostępnych obrazów.',
  'product.relatedPromotions': 'Powiązane promocje',
  'product.reported': 'Zgłoszony produkt',
  'product.saveErr': 'Błąd zapisywania produktu',
  'product.showAdvertisement': 'Pokaż reklamę',
  'product.showImages': 'Pokaż obrazy',
  'product.showTemplates': 'Również szablony',
  'product.succ': 'Produkt zapisany',
  'product.succReplaced': 'Produkt został zastąpiony',
  'product.toReplace': 'Produkt który zostanie zastąpiony',
  'productCategories.brick': 'Kategoria produktu: moduł',
  'productDescription.createNew': 'Stwórz nowy opis produktu',
  'productDescription.createCopy': 'Stwórz kopię opisu produktu',
  'productDescription.err': 'Błąd wczytywania opis produktu',
  'productDescription.listErr': 'Błąd wczytywania listy opisów produktów',
  'productDescription.duplicate': 'Taki sam opis produktu już istnieje',
  'productDescription.replaceErr': 'Błąd zastępowania opisu',
  'productLine.duplicate': 'Taka sama linia produktowa już istnieje',
  'productLine.err': 'Błąd wczytywania linii produktowej',
  'productLine.listErr': 'Błąd wczytywania listy linii produktowej',
  'productLine.name': 'Nazwa linii produktowej',
  'productLine.productsOf': 'Produkty z',
  'productLine.saveErr': 'Błąd podczas zapisywania linii produktowej',
  'productLine.succ': 'Linia produktowa zapisana',
  'productSearch.alreadySelected': 'Produkt już wybrany',
  'productSearch.bundling': 'Bundling',
  'productSearch.type': 'Typ',
  'productSearch.giveAway': 'Gratis',
  'productSearch.giveAwayBundling': 'Gratis / Bundling',
  'productSearch.createBPCO': 'Stwórz nowy MGO',
  'productSearch.clonePromotion': 'Kopiuj promocje',
  'productSearch.clonePromotionSelected': 'Kopiuj wybrane promocje',
  'productSearch.createProduct': 'Stwórz nowy produkt',
  'productSearch.results': 'Znalezione produkty',
  'productSearch.toggleSearch': 'Szukaj w markach-grupach produktów-obiektach',
  'productsSelector.avail': 'Dostępne produkty',
  'productsSelector.selected': 'Wybrane produkty',
  'promotion.copied': 'Dane zapisane w schowku',
  'promotion.copiedErr': 'Brak danych w schowku',
  'promotion.derivedPropsExplanation': 'Te wartości podane w ulotce będą miały zastosowanie do tego produktu',
  'promotion.more': 'Jeszcze {count}',
  'promotion.overridePropsExplanation': 'Ustaw nową wartość dla tego produktu, aby zastąpić wartości z ulotki',
  'promotion.searchByProduct': 'Wyniki są ograniczone do wybranego produktu',
  'promotion.validFrom': 'Promocja ważna od',
  'promotion.validTo': 'Promocja ważna do',
  'promotion.validity': 'Ważność promocji',
  'reportedFramesBrowser.fixAdvertisementBtn': 'Popraw promocje',
  'reportedFramesBrowser.fixBtn': 'Popraw ramki',
  'reportedFramesSummary.pageNo': 'Strona',
  'reportProduct.label': 'Zgłoś produkt',
  'retailer.duplicate': 'To przedsiębiorstwo handlowe już istnieje',
  'retailer.saveErr': 'Błąd zapisywania przedsiębiorstwa handlowego',
  'retailer.succ': 'Przedsiębiorstwo handlowe zapisane',
  'retailerHQ.create': 'Nowa siedziba przedsiębiorstwa handlowego',
  'retailerHQ.err': 'Błąd pobierania siedziby przedsiębiorstwa handlowego',
  'retailerHQ.name': 'Nazwa siedziby przedsiębiorstwa handlowego',
  'retailerHQ.names': 'Siedziby przedsiębiorstwa handlowego',
  'retailerHQ.saveErr': 'Błąd podczas zapisywania siedziby przedsiębiorstwa handlowego',
  'retailerHQ.succ': 'Zapisano siedzibę przedsiębiorstwa handlowego',
  'retailerHQList.err': 'Błąd pobierania siedzib przedsiębiorstw handlowych',
  'searchProductLine': 'Szukaj linii produktowej',
  'spotUpload.mandatoryFieldsInfo': 'Aby zapisać transkrypt, należy wypełnić wszystkie wymagane pola.',
  'spotUpload.nothingToVerify': 'nic do zweryfikowania',
  'spotUploadNavButton.spotUploadCTA': 'Wgraj spot',
  'spotUpload.radioSpot': 'Spot radiowy',
  'spotUpload.tvSpot': 'Spot telewizyjny',
  'statistics.generalStatistics': 'Statystyki ogólne',
  'statistics.manuallyCreatedPromotions': 'Ręcznie utworzone promocje',
  'statistics.manuallyCreatedPromotionsInfoBtn': 'Dane można wyeksportować klikając na przycisk "Eksportuj do programu Excel"',
  'statistics.media': 'Liczba nośników reklamowych',
  'statistics.mediaErr': 'Błąd wczytywania danych multimedialnych',
  'statistics.prod': 'Liczba produktów',
  'statistics.promoErr': 'Błąd wczytywania danych promocji',
  'statistics.wkYr': 'Tydzień\n\\\nRok',
  'statistics.selectCountries': 'Wybierz kraje do raportu',
  'supplier.created': 'Utworzono dostawcę',
  'supplier.error': 'Błąd wczytywania danych dostawcy',
  'supplier.errorCreate': 'Błąd tworzenia dostawcy',
  'supplier.errorDuplicate': 'Taki dostawca już istnieje',
  'supplier.errors': 'Błąd pobierania listy dostawców',
  'supplier.errorUpdate': 'Błąd aktualizacji danych dostawcy',
  'supplier.noSupAvailable': 'Brak dostępnych dostawców',
  'supplier.search': 'Szukaj dostawcy',
  'supplier.select': 'Wybierz dostawcę',
  'supplier.selectAndBrand': 'Wybierz markę/dostawcę',
  'supplier.updated': 'Zaktualizowano dane dostawcy',
  'supplier.usageCount': 'Liczba użyć',
  'timeRange.currentWeek': 'Bieżący tydzień',
  'timeRange.today': 'Dzisiaj',
  'timeRange.yesterday': 'Wczoraj',
  'typeOfBranchSelector.err': 'Błąd wczytywania typu filii',
  'v.notANumber': 'Wartość nie jest liczbą',
  'v.invalidChars': 'Dozwolone tylko cyfry i znaki -+*()/,. ',
  'v.invalidFormulaProduct': 'Błędny wynik formuły',
  'v.outOfRange': 'Wartość poza zakresem',
  'v.paramsRequired': 'Wymagana cena lub rabat',
  'v.promoLessThanRegular': 'Cena promocyjna musi być mniejsza od regularnej',
  'v.requiredIsEmpty': 'Wymagane',
  'v.zeroNegative': 'Wartość ujemna lub zero',
  bg: 'Български',
  cs: 'Český',
  de: 'Deutsch',
  'de_AT': 'Deutsch (AT)',
  'de_CH': 'Deutsch (CH)',
  en: 'English',
  fr: 'Français',
  hr: 'Hrvatski',
  hu: 'Magyar',
  nl: 'Nederlands',
  pl: 'Polski',
  ro: 'Română',
  sk: 'Slovenský',
  sl: 'Slovenščina',
  it: 'Italiano'
};

export default pl;
