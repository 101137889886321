import {FormattedMessage} from 'react-intl';
import {mediaSummary, spotHeaderData} from 'shared/models/media.model';

interface mediaSummaryProps {
    data: mediaSummary
}

export const buildMediaSummary = (headerData: spotHeaderData) => {
    return {
        banner: headerData?.bannerName,
        language: headerData?.language,
        validFrom: headerData?.validFrom,
        validTo: headerData?.validTo
    }
}

const MediaSummary = ({data}: mediaSummaryProps) => {
    return (
        <div>
            <FormattedMessage id="b.banner"/>: {data && <span className="_bold">{data?.banner ? data.banner : '-'}</span>}
            <br/>
            <FormattedMessage id="dictionaryElement.lang"/>: {data && <span className="_bold">{data?.language ? data.language.toUpperCase() : '-'}</span>}
            <br/>
            <FormattedMessage id="printMediaOverview.validityDates"/>: {data && <span className="_bold">{data?.validFrom} - {data?.validTo}</span>}
        </div>
    )
}

export default MediaSummary;