import { ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, MenuItemProps } from '@mui/material';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { releaseIfLocked } from 'utils/lockUtils';
import { useFormatMessage } from 'utils/translate';
import { dialogIdentifier, menuItemIdentifier, NavigationItem, NavigationSection, navigationSections } from './NavigationMenuItems';
import { leafletUploadDialogToggle } from 'shared/reducers/leafletUpload';
import { IRootState } from 'shared/reducers';
import { spotUploadDialogToggle } from 'shared/reducers/spotUpload';
import { isSomeEnum } from 'utils/isSomeEnum';
import { useLocation } from 'react-router';
import { paths } from 'paths';
import { useEffect } from 'react';
import { fetchNextSpotToVerify } from 'shared/reducers/nextSpotToVerify';

type NavigationMenuProps = {
    anchorEl: HTMLElement | null,
    onClose: () => void,
};

type NavigationMenuItemProps = MenuItemProps & {
    item: NavigationItem,
};

const NavigationMenuItem = ({ item, children, ...restOfProps }: NavigationMenuItemProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const isNotDialogIdentifier: <T>(e: T) => boolean = isSomeEnum(dialogIdentifier);
    const isNotUploadDialog: boolean = !isNotDialogIdentifier(item.id);
    const matchesCurrentRoute: boolean = !!useRouteMatch({
        path: item.path,
        exact: true,
    }) && isNotUploadDialog;
    const leafletUploadOpen: boolean = useSelector((state: IRootState) => state.leafletUpload.dialogOpen);
    const disableLeafletUpload: boolean = leafletUploadOpen && item.id === dialogIdentifier.leafletUpload
    const spotUploadOpen: boolean = useSelector((state: IRootState) => state.spotUpload.dialogOpen);
    const disableSpotUpload: boolean = spotUploadOpen && item.id === dialogIdentifier.spotUpload
    const nextSpotIdToVerify: string = useSelector((state: IRootState) => state.nextSpotToVerify.data?.spotId);
    const disableVerification: boolean = item.id === menuItemIdentifier.mediumVerification && (!nextSpotIdToVerify || (location.pathname === paths.spotHeaderData && location.search.includes(`id=${nextSpotIdToVerify}`)));

    const handleItemClick = () => {
        releaseIfLocked();
        dispatch(leafletUploadDialogToggle(false));
        dispatch(spotUploadDialogToggle(false));
        let params: string = item.params;
        if (item.id === menuItemIdentifier.mediumVerification && nextSpotIdToVerify) {
            params = `?id=${nextSpotIdToVerify}`
        }
        history.push(`${item.path}${params ?? ''}`);
        if (item.action) {
            dispatch(item.action);
        }
    };

    return (
        <MenuItem onClick={handleItemClick} {...restOfProps} disabled={matchesCurrentRoute || disableLeafletUpload || disableSpotUpload || disableVerification}>
            {children}
        </MenuItem>
    );
};

const NavigationMenuSection = ({ headingLabelId, items }: NavigationSection) => {
    const translate = useFormatMessage();

    return (
        <>
            <ListSubheader>{translate({ id: headingLabelId })}</ListSubheader>
            {items.map((item) => (
                <NavigationMenuItem key={item.path} item={item}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText>{translate({ id: item.labelId })}</ListItemText>
                </NavigationMenuItem>
            ))}
        </>
    );
};

export const NavigationMenu = ({ anchorEl, onClose }: NavigationMenuProps) => {

    const dispatch = useDispatch();
    const country = useSelector((state: IRootState) => state.userProfile.countryMarket.preferredCountry);

    useEffect(() => {
        dispatch(fetchNextSpotToVerify(country));
    }, [country, dispatch])

    return (
        <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onClose}>
            {navigationSections.map(({ ...props }) => (
                <NavigationMenuSection key={props.headingLabelId} {...props} />
            ))}
        </Menu>
    );
};
