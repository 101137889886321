
export enum mimeTypes {
    pdf = 'application/pdf',
    audio = 'audio/mpeg',
    video = 'video/mp4',
    jpg = 'image/jpg',
    jpeg = 'image/jpeg'
}
export const supportedLeafletFileTypes: string[] = [mimeTypes.pdf];

export const supportedRadioSpotFileTypes: string[] = [mimeTypes.audio];

export const supportedTvSpotFileTypes: string[] = [mimeTypes.video];

export const supportedPressAdvFileTypes: string[] = [mimeTypes.pdf, mimeTypes.jpg, mimeTypes.jpeg];