import './DetailsRow.scss';

import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {Button} from '@mui/material';
import {paths} from 'paths';
import { getId } from 'utils/routing';
import {FramePreview, MediaPreview} from 'components/gfx';
import {mediumType as mediumTypeEnum} from 'shared/models/leaflet.model';
import {zoomTypes} from 'components/gfx/FramePreview/FramePreview';

interface detailsRowProps {
    frameId: string,
    leafletId: string,
    mediumId: string,
    mediumType: mediumTypeEnum,
    pageNumber: number
}

const DetailsRow = (props: detailsRowProps) => {
    const {frameId, leafletId, mediumId, mediumType, pageNumber} = props;

    const isSpot: boolean = mediumType === mediumTypeEnum.RADIO_SPOT || mediumType === mediumTypeEnum.TV_SPOT;

    function switchDetailsRowView() {
        const relatedPromoId = getId();
        if (isSpot)
            return `${paths.advertisementBrowserMedia}?id=${mediumId}&from=${paths.advertisementsOverviewPromotions}`;
        else
            return `${paths.advertisementBrowser}?id=${leafletId}&page=${pageNumber - 1}&frameId=${frameId}&from=${
                paths.advertisementsOverviewPromotions
            }${relatedPromoId ? `&relatedPromoId=${relatedPromoId}` : ""}`;
    }

    return (
        <div className="promotionsOverviewDetailsRowRoot">
            <div className="previewContainer">
                {isSpot
                ?
                <MediaPreview mediumId={mediumId}/>
                :
                <FramePreview frameId={frameId} zoomType={zoomTypes.dialog}/>
                }
            </div>
            <div className="actions">
                <Link to={switchDetailsRowView()}>
                    <Button variant="contained" size="small"><FormattedMessage id="a.edit"/>
                    </Button>
                </Link>
            </div>
        </div>
    );
}

export default DetailsRow;